import React from 'react';
import { Helmet } from 'react-helmet';
import GoalCard from '../components/GoalCard';
import { graphql } from 'gatsby';
import PageWrapper from '../components/PageWrapper';
import HeaderButton from '../components/HeaderButton';

const Topics = ({ data }) => {
  console.log(data);
  console.log(data.allContentfulGoal.edges.length);
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: <HeaderButton />,
          footerStyle: 'style3',
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>UStrive | Topics</title>
          <link rel="canonical" href="https://ustrive.com/topics" />
        </Helmet>
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Popular Topics</h2>
                  <p className="font-size-7 mb-7">
                    Our mentors can help you with a wide variety of topics
                    during your college &amp; career journeys. See some of our
                    latest topics below!
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-7">
              {data.allContentfulGoal.edges.map((goal) => (
                <div key={goal.node.id} className="col-md-4">
                  <GoalCard goal={goal.node} />
                </div>
              ))}
              {/* {data.allContentfulBlogPost.edges.map((post) => (
                <BlogPost post={post} />
              ))} */}
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export const pageQuery = graphql`
  query goals {
    allContentfulGoal(limit: 50) {
      edges {
        node {
          id
          contentful_id
          createdAt(formatString: "MM/DD/YY")
          title
          description {
            description
          }
        }
      }
    }
  }
`;

export default Topics;
