import React from 'react';
import { Link } from 'gatsby';
const GoalCard = ({ goal }) => {
  return (
    <Link
      to={`/goals/${goal.contentful_id}`}
      className="bg-white shadow-2 px-10 pt-9 pb-10 mb-9 gr-hover-1"
    >
      {/* <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
        <p className="font-size-5 text-bali-gray mb-0 mr-5 mb-5 d-flex align-items-center">
          <i className="fa fa-map-marker-alt mr-1 text-bali-gray"></i>
          <span>{goal.description.description}</span>
        </p>
        <div
          className={`bg-${options.color}-op1 text-${options.color} px-3 py-2 font-size-3 rounded-5 mb-5 min-w-76 text-center`}
        >
          {options.jobType}
        </div>
      </div> */}
      <h4 className="font-size-8 text-dark-cloud font-weight-medium pr-md-10 pr-lg-0 pr-xl-13 line-height-34">
        {goal.title}
      </h4>
      {goal.description && (
        <p className="font-size-5 text-bali-gray mb-0 mr-5 mb-5 d-flex align-items-center">
          <span>{goal.description.description}</span>
        </p>
      )}

      {/* <div className="media">
        <div className="mr-5">
          <img className="circle-34" src={options.image} alt="" />
        </div>
        <div className="user-info">
          <h5 className="font-size-6 font-weight-medium text-dark-cloud line-height-reset mb-1">
            {options.companyName}
          </h5>
          <p className="font-size-3 text-bali-gray mb-0">{options.postTime}</p>
        </div>
      </div> */}
    </Link>
  );
};

export default GoalCard;
